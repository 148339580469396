import { ArrowLeftOutline24Icon, Box, Heading, HStack, Flex } from "@vygruppen/spor-react";
import { Link } from "react-router-dom";
import { SearchInput } from "./SearchInput";
import { AddReportLink } from "./AddReportLink";

type StandardHeaderProps = {
    name: string;
    backLinkTo?: string;
    showSearch?: boolean;
    showAddReport?: boolean;
};

export const StandardHeader = ({ name, backLinkTo, showSearch = true, showAddReport = true }: StandardHeaderProps) => {
    return (
        <Flex flexDirection={["column", "row"]} justifyContent="space-between" verticalAlign="middle">
            {backLinkTo != null ? (
                <TitleWithBacklink name={name} linkTo={backLinkTo} />
            ) : (
                <TitleNoBacklink name={name} />
            )}
            <Flex alignItems="center" marginTop={[1, 0]}>
                {showAddReport && <AddReportLink />}
                {showSearch && <SearchInput />}
            </Flex>
        </Flex>
    );
};

type TitleWithBacklinkProps = {
    name: string;
    linkTo: string;
};

const TitleWithBacklink = ({ name, linkTo }: TitleWithBacklinkProps) => (
    <HStack>
        <Link to={linkTo}>
            <ArrowLeftOutline24Icon
                height="30px"
                width="30px"
                color="alias.darkTeal"
                title={"Tilbake til forrige side"}
            />
        </Link>
        <Box textAlign={["center", "left"]} paddingLeft={[0, 2]} width="100%">
            <Heading fontSize="lg" fontWeight="600">
                {name}
            </Heading>
        </Box>
    </HStack>
);

type TitleNoBacklinkProps = {
    name: string;
};

const TitleNoBacklink = ({ name }: TitleNoBacklinkProps) => (
    <Heading fontSize="xl" fontWeight="600">
        {name}
    </Heading>
);
