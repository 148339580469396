import React, { useState } from "react";
import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Textarea,
    Button,
} from "@vygruppen/spor-react";
import { Heading } from "@chakra-ui/react";
import { FeedbackSmiley, postFeedback } from "./FeedbackApi";
import { FeedbackEmojiButton } from "./FeedbackEmojiButton";

type FeedbackModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const FeedbackModal = ({ isOpen, onClose }: FeedbackModalProps) => {
    const [selectedSmiley, setSelectedSmiley] = useState<FeedbackSmiley | null>(null);
    const [comment, setComment] = useState("");

    const submitOnClose = () => {
        if (selectedSmiley != null) {
            postFeedback({
                smiley: selectedSmiley,
                comment,
            });
            setComment("");
            setSelectedSmiley(null);
        }
        onClose();
    };

    const allSmileys = [FeedbackSmiley.SAD, FeedbackSmiley.NEUTURAL, FeedbackSmiley.HAPPY];

    return (
        <Modal size="lg" isOpen={isOpen} onClose={submitOnClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading fontSize="lg">Hva synes du om Vy Tall?</Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex marginTop={2} marginBottom={4} justifyContent="space-around">
                        {allSmileys.map((smiley) => (
                            <FeedbackEmojiButton
                                key={smiley}
                                value={smiley}
                                selectedValue={selectedSmiley}
                                onClick={setSelectedSmiley}
                            />
                        ))}
                    </Flex>
                    {selectedSmiley && (
                        <>
                            <Textarea
                                label="Fortell oss gjerne mer hva du synes"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                marginBottom={4}
                                rows={2}
                            />
                            <Button variant="secondary" onClick={submitOnClose} isFullWidth={true}>
                                Send tilbakemelding
                            </Button>
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
