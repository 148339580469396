import { Box } from "@vygruppen/spor-react";
import { KeyFigures } from "./KeyFigures";
import { StandardHeader } from "./StandardHeader";

type HeaderWithKeyFiguresProps = {
    name: string;
    showSearch?: boolean;
    showAddReport?: boolean;
};

export const HeaderWithKeyFigures = ({ name, showSearch = true, showAddReport = true }: HeaderWithKeyFiguresProps) => {
    return (
        <Box>
            <StandardHeader name={name} showAddReport={showAddReport} showSearch={showSearch} />
            <KeyFigures />
        </Box>
    );
};
