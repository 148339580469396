import React, { useState } from "react";
import { Card, Flex, Box, Heading, Text, Button, CardProps } from "@vygruppen/spor-react";
import { LikeOutline30Icon } from "@vygruppen/spor-icon-react";
import { FeedbackModal } from "./FeedbackModal";

type FeedbackProps = Omit<CardProps, "variant" | "colorScheme">;

export const Feedback = (props: FeedbackProps) => {
    const [modalOpen, setModalOpen] = useState(false);

    const onCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Card marginTop={8} variant="filled" colorScheme="green" {...props}>
                <Flex padding={4}>
                    <Box marginRight={4}>
                        <LikeOutline30Icon />
                    </Box>
                    <Box>
                        <Heading marginBottom={2} fontSize="sm" fontWeight="bold" as="h3">
                            Hjelp oss å bli bedre!
                        </Heading>
                        <Box>
                            <Text marginBottom={2}>
                                Vy Tall er fortsatt under utvikling og trenger din tilbakemelding.
                            </Text>
                            <Text margin={0}>Hva kunne vært bedre?</Text>
                            <Text margin={0}>Fant du det du lette etter?</Text>
                            <Text marginBottom={3}>Eller noe annet du vil si?</Text>
                            <Button variant="additional" size="xs" onClick={() => setModalOpen(true)}>
                                Gi oss tilbakemelding
                            </Button>
                        </Box>
                    </Box>
                </Flex>
            </Card>
            <FeedbackModal isOpen={modalOpen} onClose={onCloseModal} />
        </>
    );
};
