import React from "react";
import { SimpleGrid, GridItem } from "@vygruppen/spor-react";
import { DomeneKort } from "./DomeneKort";
import { AppContainer } from "../../components/AppContainer";
import { categories } from "../../common/categories";
import { Feedback } from "../../components/Feedback/Feedback";
import { HeaderWithKeyFigures } from "../../components/Header/HeaderWithKeyFigures";

export const Oversiktside = () => {
    return (
        <AppContainer header={<HeaderWithKeyFigures name="Vy tall" showAddReport={false} />}>
            <SimpleGrid columns={[1, 2, 3, 4]} gap={4}>
                {categories.map((category) => (
                    <DomeneKort
                        key={category.urlname}
                        to={`/${category.urlname}`}
                        name={category.name}
                        icon={category.icon}
                    />
                ))}
            </SimpleGrid>
            <SimpleGrid columns={[1, 2, 3, 4]} gap={4}>
                <GridItem colSpan={[1, 2, 1, 2]} colStart={[1, 1, 2, 2]}>
                    <Feedback />
                </GridItem>
            </SimpleGrid>
        </AppContainer>
    );
};
