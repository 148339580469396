import { useEffect } from "react";
import { isDuringInitialization, isAuthenticated, LoginFlowState } from "auth/loginFlowTypes";
import useLoginFlow from "auth/useLoginFlow";
import { UnauthenticatedApp } from "UnauthenticatedApp";
import { AuthenticatedApp } from "AuthenticatedApp";
import { useLocation } from "react-router-dom";
import { log } from "./common/log";
import { FullScreenLoading } from "./components/Loading";

type AppStatus = "Initialising" | "Unauthenticated" | "Authenticated";

const getAppStatus = (state: LoginFlowState): AppStatus => {
    if (isDuringInitialization(state)) {
        return "Initialising";
    }

    if (isAuthenticated(state)) {
        return "Authenticated";
    }

    return "Unauthenticated";
};

function App() {
    const { state } = useLoginFlow(true);
    const appStatus = getAppStatus(state);
    const location = useLocation();

    useEffect(() => {
        log.pageview(location.pathname);
    }, [location.pathname]);

    switch (appStatus) {
        case "Initialising":
            return <FullScreenLoading />;

        case "Authenticated":
            return <AuthenticatedApp />;

        case "Unauthenticated":
            return <UnauthenticatedApp />;
    }
}

export default App;
