import {
    KronerOutline30Icon,
    CopyOutline24Icon,
    WalletOutline30Icon,
    ProfileOutline30Icon,
    SettingsX1Outline30Icon,
    PersonOutline24Icon,
    LikeOutline24Icon,
    IconComponent,
} from "@vygruppen/spor-icon-react";

type Category = {
    id: number;
    name: string;
    urlname: string;
    icon: IconComponent;
};

export const categories: Category[] = [
    {
        id: 1,
        name: "Salg og reisende",
        urlname: "salg-og-reisende",
        icon: KronerOutline30Icon,
    },
    {
        id: 2,
        name: "Økonomi",
        urlname: "okonomi",
        icon: WalletOutline30Icon,
    },
    {
        id: 3,
        name: "Trafikk og teknikk",
        urlname: "trafikk-og-teknikk",
        icon: SettingsX1Outline30Icon,
    },
    {
        id: 4,
        name: "HR, HMS og kvalitet",
        urlname: "hr-hms-kvalitet",
        icon: ProfileOutline30Icon,
    },
    {
        id: 5,
        name: "Styringskort",
        urlname: "styringskort",
        icon: CopyOutline24Icon,
    },
    {
        id: 6,
        name: "Kundeinnsikt",
        urlname: "kundeinnsikt",
        icon: LikeOutline24Icon,
    },
    {
        id: 7,
        name: "Vedlikehold og lager",
        urlname: "vedlikehold-og-lager",
        icon: SettingsX1Outline30Icon,
    },
    {
        id: 9,
        name: "Personellplanlegging",
        urlname: "personellplanlegging",
        icon: PersonOutline24Icon,
    },
];
