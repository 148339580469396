import React, { useRef, useState } from "react";
import { FormControl, Input, Textarea, Select, Button, Box, Flex, Image, FormLabel } from "@vygruppen/spor-react";
import { useField, FieldHookConfig } from "formik";
import { ReportDTO } from "api/UseReports";

type InputProps = {
    label: string;
    textarea?: boolean;
};

export const CustomInput: React.FC<FieldHookConfig<string> & InputProps> = ({ textarea = false, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <FormControl isInvalid={meta.touched && !!meta.error} mb={2}>
            {textarea ? (
                <Textarea {...field} label={props.label} id={`${props.id}-${props.name}-input`} rows={3} />
            ) : (
                <Input {...field} label={props.label} id={`${props.id}-${props.name}-input`} />
            )}
        </FormControl>
    );
};

type ImageInputProps = {
    label: string;
    images: File[] | null;
    setImages: (images: File[] | null) => void;
};

export const ImageInput: React.FC<ImageInputProps> = ({ label, images, setImages }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImageChange = (files: FileList | null) => {
        if (files) {
            setImages(Array.from(files));
        }
    };

    const handleRemoveImages = () => {
        setImages(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    return (
        <Box marginTop={2} marginBottom={4}>
            <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e.target.files)}
                ref={fileInputRef}
                hidden
                multiple
            />
            <FormLabel>{label}</FormLabel>
            {images && (
                <Flex marginY={2} direction="row">
                    {images.map((image, index) => (
                        <Image
                            key={index}
                            src={URL.createObjectURL(image)}
                            alt={image.name}
                            width={100}
                            marginRight={1}
                        />
                    ))}
                </Flex>
            )}
            {images ? (
                <Button variant="secondary" size="xs" onClick={handleRemoveImages}>
                    Fjern bilder
                </Button>
            ) : (
                <Button variant="secondary" size="xs" onClick={() => fileInputRef.current?.click()}>
                    Velg bilder
                </Button>
            )}
        </Box>
    );
};

type CustomPreviewImageInputProps = {
    reportToEdit?: ReportDTO;
    images: File[] | null;
    setImages: (images: File[] | null) => void;
};

export const CustomPreviewImageInput = ({ reportToEdit, images, setImages }: CustomPreviewImageInputProps) => {
    const [editImages, setEditImages] = useState(false);
    if (reportToEdit && reportToEdit.previewImages && !editImages) {
        return (
            <Box marginY={2}>
                <FormLabel>Bilder</FormLabel>
                {reportToEdit.previewImages.length > 0 && (
                    <Flex marginY={2} direction="row">
                        {reportToEdit.previewImages.map((imageUrl, index) => (
                            <Image key={index} src={imageUrl} width={100} marginRight={1} />
                        ))}
                    </Flex>
                )}
                <Button variant="secondary" size="xs" onClick={() => setEditImages(true)} marginBottom={2}>
                    {reportToEdit.previewImages.length === 0 ? "Legg til bilder" : "Fjern bilder"}
                </Button>
            </Box>
        );
    }

    return <ImageInput label="Bilder" images={images} setImages={setImages} />;
};

type DropdownProps = {
    label: string;
    options: { name: string; value: number | string }[];
};

export const CustomDropdown: React.FC<FieldHookConfig<string> & DropdownProps> = ({ options, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <FormControl isInvalid={meta.touched && !!meta.error} mb={2}>
            <Select {...field} label={props.label}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};
