import { useState } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@vygruppen/spor-react";
import { Heading, Button } from "@chakra-ui/react";
import { mutateReportsForCategory, ReportDTO } from "api/UseReports";
import { postFile, postReport, putReport } from "./AdminApi";
import { Formik, Form } from "formik";
import { categories } from "../../common/categories";
import { CustomDropdown, CustomInput, CustomPreviewImageInput } from "../../components/Form/CustomInput";

type AddReportModalProps = {
    isOpen: boolean;
    reportToEdit?: ReportDTO;
    onClose: () => void;
};

const NEW_REPORT_ID = -1;

export const AddReportModal = ({ isOpen, onClose, reportToEdit }: AddReportModalProps) => {
    const [images, setImages] = useState<File[] | null>(null);

    const handleOnClose = () => {
        setImages(null);
        onClose();
    };

    const uploadImages = async (): Promise<string[] | null> => {
        if (images) {
            const fileNames = await Promise.all(
                images.map(async (image) => {
                    const fileName = await postFile(image);
                    return fileName.value;
                })
            );
            return fileNames;
        }
        return null;
    };

    const submitReport = async (report: ReportDTO) => {
        const newReport: ReportDTO = { ...report, previewImages: await uploadImages() };

        if (report.id === NEW_REPORT_ID) {
            await postReport(newReport);
        } else {
            await putReport(newReport);
        }
        mutateReportsForCategory(report.categoryId);
    };

    const getInitialValues = () => {
        return {
            id: reportToEdit?.id || NEW_REPORT_ID,
            categoryId: reportToEdit?.categoryId || 1,
            name: reportToEdit?.name || "",
            owner: reportToEdit?.owner || "",
            shortDescription: reportToEdit?.shortDescription || "",
            longDescription: reportToEdit?.longDescription || "",
            sources: reportToEdit?.sources || "",
            lastUpdated: reportToEdit?.lastUpdated || "",
            url: reportToEdit?.url || "",
        };
    };

    return (
        <Modal size="xl" isOpen={isOpen} onClose={handleOnClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading fontSize="lg">{reportToEdit != null ? "Oppdater rapport" : "Legg til ny rapport"}</Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik
                        initialValues={getInitialValues()}
                        onSubmit={async (val, actions) => {
                            await submitReport({ ...val });
                            actions.setSubmitting(false);
                            handleOnClose();
                        }}
                    >
                        {(props) => (
                            <Form>
                                <CustomDropdown
                                    name="categoryId"
                                    label="Kategori"
                                    options={categories.map((category) => ({
                                        name: category.name,
                                        value: category.id,
                                    }))}
                                />
                                <CustomInput name="name" label="Tittel" />
                                <CustomInput name="owner" label="Ansvarlig" />
                                <CustomInput name="shortDescription" label="Kort beskrivelse" />
                                <CustomInput name="longDescription" label="Lang beskrivelse" textarea={true} />
                                <CustomPreviewImageInput
                                    reportToEdit={reportToEdit}
                                    images={images}
                                    setImages={setImages}
                                />
                                <CustomInput name="lastUpdated" label="Sist oppdatert" />
                                <CustomInput name="sources" label="Kilde" />
                                <CustomInput name="url" label="Rapport URL" />
                                <Button
                                    variant="secondary"
                                    type="submit"
                                    isFullWidth={true}
                                    mt={2}
                                    isLoading={props.isSubmitting}
                                >
                                    {reportToEdit != null ? "Oppdater rapport" : "Legg inn rapport"}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
