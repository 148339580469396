import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@vygruppen/spor-react";
import { Heading, Button } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { CustomDropdown, CustomInput } from "../../components/Form/CustomInput";
import { KeyFigureDTO, KeyFigureWithIdDTO, mutateKeyFigures } from "../../api/UseKeyFigures";
import { postKeyFigure, putKeyFigure } from "./AdminKeyfiguresApi";
import { KeyFigureIcons } from "../../common/KeyFigureIcons";

type AddReportModalProps = {
    isOpen: boolean;
    keyFigureToEdit?: KeyFigureWithIdDTO;
    onClose: () => void;
};

export const AddUpdateKeyfiguresModal = ({ isOpen, onClose, keyFigureToEdit }: AddReportModalProps) => {
    const submitKeyFigure = async (keyfigure: KeyFigureDTO) => {
        if (keyFigureToEdit == null) {
            await postKeyFigure(keyfigure);
        } else {
            await putKeyFigure(keyFigureToEdit.id, keyfigure);
        }
        mutateKeyFigures();
    };

    const getInitialValues = () => {
        return {
            icon: keyFigureToEdit?.icon || KeyFigureIcons[0].name,
            title: keyFigureToEdit?.title || "",
            keynumber: keyFigureToEdit?.keynumber || "",
            goal: keyFigureToEdit?.goal || "",
            currentvalue: keyFigureToEdit?.currentvalue || "",
            information: keyFigureToEdit?.information || "",
        };
    };

    const previousYear = new Date().getFullYear() - 1;

    return (
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading fontSize="lg">
                        {keyFigureToEdit != null ? "Oppdater nøkkeltall" : "Legg til nytt nøkkeltall"}
                    </Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik
                        initialValues={getInitialValues()}
                        onSubmit={async (val, actions) => {
                            await submitKeyFigure({
                                icon: val.icon,
                                title: val.title,
                                keynumber: val.keynumber,
                                goal: val.goal,
                                currentvalue: val.currentvalue,
                                information: val.information,
                            });
                            actions.setSubmitting(false);
                            onClose();
                        }}
                    >
                        {(props) => (
                            <Form>
                                <CustomDropdown
                                    name="icon"
                                    label="Ikon"
                                    options={KeyFigureIcons.map((keyfigure) => ({
                                        name: keyfigure.name,
                                        value: keyfigure.name,
                                    }))}
                                />
                                <CustomInput name="title" label="Tittel" />
                                <CustomInput name="keynumber" label="Hovedtall" />
                                <CustomInput name="goal" label={`Tilsvarende periode i ${previousYear}`} />
                                <CustomInput name="currentvalue" label="Hittil i år" />
                                <CustomInput name="information" label="Informasjon" textarea={true} />
                                <Button
                                    variant="secondary"
                                    type="submit"
                                    isFullWidth={true}
                                    mt={2}
                                    isLoading={props.isSubmitting}
                                >
                                    {keyFigureToEdit != null ? "Oppdater nøkkeltall" : "Legg til nøkkeltall"}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
