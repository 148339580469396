import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Box, Image, Button, ArrowLeftOutline18Icon, ArrowRightOutline18Icon } from "@vygruppen/spor-react";
import { ReportImagePreviewModal } from "./ReportImagePreviewModal";

const renderPreviewIndicator = (
    clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean
) => (
    <Button size="xs" backgroundColor="none" onClick={clickHandler} padding={0} marginY={0} marginX={1}>
        <Box width="14px" height="14px" borderRadius="50%" backgroundColor={isSelected ? "green" : "gray"} />
    </Button>
);

const renderArrowPrev = (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, hasPrev: boolean) =>
    hasPrev && (
        <Button size="xs" onClick={clickHandler} padding={0} zIndex={1000} position="absolute" bottom={"55%"} left={0}>
            <ArrowLeftOutline18Icon />
        </Button>
    );

const renderArrowNext = (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, hasNext: boolean) =>
    hasNext && (
        <Button size="xs" onClick={clickHandler} padding={0} zIndex={1000} position="absolute" bottom={"55%"} right={0}>
            <ArrowRightOutline18Icon />
        </Button>
    );

type ReportImagePreviewProps = {
    previewImages?: string[] | null;
    reportName: string;
};

export const ReportImagePreview = ({ previewImages, reportName }: ReportImagePreviewProps) => {
    const [isLargePreviewOpen, setIsLargePreviewOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const filteredImages = previewImages?.filter((image) => image.trim() !== "");
    if (filteredImages == null || filteredImages.length === 0) {
        return null;
    }

    const isMultipleImages = filteredImages.length > 1;

    return (
        <Box marginTop={4}>
            <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                showIndicators={isMultipleImages}
                renderIndicator={renderPreviewIndicator}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
                onChange={(index) => setCurrentIndex(index)}
            >
                {filteredImages.map((imageUrl, index) => (
                    <Box
                        key={index}
                        marginBottom={isMultipleImages ? 8 : 2}
                        onClick={() => setIsLargePreviewOpen(true)}
                    >
                        <Image height="200px" objectFit="contain" src={imageUrl} />
                    </Box>
                ))}
            </Carousel>
            <ReportImagePreviewModal
                reportName={reportName}
                onClose={() => setIsLargePreviewOpen(false)}
                isOpen={isLargePreviewOpen}
                imageUrl={filteredImages[currentIndex]}
            />
        </Box>
    );
};
