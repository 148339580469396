import React, { useState } from "react";
import { ReportDTO, useReports } from "api/UseReports";
import { AppContainer } from "components/AppContainer";
import { Outlet } from "react-router-dom";
import { FullScreenLoading } from "components/Loading";
import { Box, Text, Center, Button, Flex, DeleteOutline24Icon, EditOutline24Icon } from "@vygruppen/spor-react";
import { DeleteReportDialog } from "./DeleteReportDialog";
import { AddReportModal } from "features/AdminReports/AddReportModal";
import { AdminHeader } from "./AdminHeader";
import { ReportTable } from "../../components/ReportTable";
import { useSearch } from "../../components/Search/SearchHook";

export const Admin = () => {
    const { reports, isLoading } = useReports();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isAddReportOpen, setIsAddReportOpen] = useState(false);
    const [editReportId, setEditReportId] = useState<number | null>(null);
    const [searchString, setSearchString] = useState<string>("");
    const [reportToDelete, setReportToDelete] = useState<ReportDTO | null>(null);

    const showDeleteDialog = (report: ReportDTO) => {
        setReportToDelete(report);
        setIsDeleteOpen(true);
    };

    const closeAddReportDialog = () => {
        setEditReportId(null);
        setIsAddReportOpen(false);
    };

    const filteredReports = useSearch(searchString, reports || []);
    const reportToEdit = reports?.find((report) => report.id === editReportId);

    return (
        <AppContainer
            showTableViewButton={false}
            header={<AdminHeader onClickAddReport={() => setIsAddReportOpen(true)} onSearchChange={setSearchString} />}
        >
            {isLoading && <FullScreenLoading />}
            {reports && (
                <Box>
                    {filteredReports.length > 0 ? (
                        <ReportTable
                            reports={filteredReports}
                            renderCtaCell={(report) => (
                                <Flex direction="row">
                                    <Button onClick={() => setEditReportId(report.id)}>
                                        <EditOutline24Icon />
                                    </Button>
                                    <Button marginLeft={1} onClick={() => showDeleteDialog(report)}>
                                        <DeleteOutline24Icon />
                                    </Button>
                                </Flex>
                            )}
                        />
                    ) : (
                        <Box mt={8}>
                            <Center>
                                <Text fontSize="md">Ingen resultater</Text>
                            </Center>
                        </Box>
                    )}
                </Box>
            )}
            <DeleteReportDialog
                onClose={() => setIsDeleteOpen(false)}
                isOpen={isDeleteOpen}
                reportToDelete={reportToDelete}
            />
            <AddReportModal
                onClose={closeAddReportDialog}
                isOpen={isAddReportOpen || editReportId != null}
                reportToEdit={reportToEdit}
            />
            <Outlet />
        </AppContainer>
    );
};
