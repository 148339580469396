import React from "react";
import { ArrowLeftOutline24Icon, Heading, HStack, TextLink, Box, Flex } from "@vygruppen/spor-react";
import { Link as ReactRouterLink, Link as RouterLink } from "react-router-dom";
import { SearchInput } from "../../components/Header/SearchInput";
import { AddReportLink } from "../../components/Header/AddReportLink";

type AdminHeaderProps = {
    onSearchChange: (value: string) => void;
    onClickAddReport: () => void;
};

export const AdminHeader = ({ onSearchChange, onClickAddReport }: AdminHeaderProps) => (
    <Flex justifyContent="space-between" verticalAlign="middle">
        <HStack>
            <RouterLink to={"/"}>
                <ArrowLeftOutline24Icon
                    height="30px"
                    width="30px"
                    color="alias.darkTeal"
                    title={"Tilbake til forrige side"}
                />
            </RouterLink>
            <Box textAlign={["center", "left"]} paddingLeft={[0, 2]} width="100%">
                <Heading fontSize="lg" fontWeight="600">
                    Admin: rapporter
                </Heading>
                <TextLink whiteSpace="nowrap" as={ReactRouterLink} to="/admin/nokkeltall">
                    Administrer nøkkeltall
                </TextLink>
            </Box>
        </HStack>
        <Flex alignItems="center">
            <AddReportLink onClick={onClickAddReport} />
            <SearchInput onChange={onSearchChange} />
        </Flex>
    </Flex>
);
