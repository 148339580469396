import { getCurrentEnvironment } from "../environment";
import appConfig from "../appConfig.json";

export const getBackendBaseUrl = () => {
    const environment = getCurrentEnvironment();
    const baseUrl = appConfig.backend.baseUrl[environment];
    return `${baseUrl}${appConfig.backend.contextPath}`;
};

export const get = <TYPE>(input: RequestInfo, init?: RequestInit): Promise<TYPE> =>
    fetch(input, init).then((res) => res.json());

export const post = <TYPE>(input: RequestInfo, body: any, init?: RequestInit): Promise<TYPE> =>
    fetch(input, {
        ...init,
        method: "POST",
        headers: { "Content-Type": "application/json", ...init?.headers },
        body: JSON.stringify(body),
    }).then((res) => {
        if (res.headers.get("Content-Length") !== "0") {
            return res.json();
        }
        return Promise.resolve();
    });
