import React from "react";
import { SimpleGrid, Box } from "@vygruppen/spor-react";
import { ReportCard } from "./ReportCard";
import { ReportDTO } from "../../api/UseReports";

type DomenesideBodyProps = {
    reports: ReportDTO[];
};

export const DomenesideBody = ({ reports }: DomenesideBodyProps) => (
    <Box>
        <SimpleGrid columns={[1, 2, 3]} gap="4">
            {reports.map((report) => (
                <ReportCard
                    key={report.id}
                    to={report.id.toString()}
                    name={report.name}
                    text={report.shortDescription}
                />
            ))}
        </SimpleGrid>
    </Box>
);
