import useSWR, { mutate } from "swr";
import { getWithAuth, getBackendBaseUrl } from "./apiClientWithAuth";
import { categories } from "../common/categories";

export type ReportDTO = {
    id: number;
    categoryId: number;
    name: string;
    owner: string;
    shortDescription: string;
    longDescription: string;
    url: string;
    sources?: string;
    lastUpdated?: string;
    previewImages?: string[] | null;
};

type UseReportsReturnType = {
    reports?: ReportDTO[];
    categoryName: string;
    isLoading: boolean;
    isError: boolean;
};

export const useReports = (categoryUrlName?: string): UseReportsReturnType => {
    const category = categoryUrlName ? categories.find((category) => category.urlname === categoryUrlName) : null;
    const { data, error } = useSWR<ReportDTO[]>(
        category ? `${getBackendBaseUrl()}/reports?categoryId=${category.id}` : `${getBackendBaseUrl()}/reports`,
        getWithAuth,
        { errorRetryCount: 3 }
    );

    return {
        reports: data,
        categoryName: category ? category.name : "",
        isLoading: !error && !data,
        isError: error,
    };
};

export const mutateReportsForCategory = (categoryId: number) => {
    mutate(`${getBackendBaseUrl()}/reports?categoryId=${categoryId}`);
    mutate(`${getBackendBaseUrl()}/reports`);
};
