import { PersonOutline24Icon, DiscountOutline24Icon, KronerOutline24Icon } from "@vygruppen/spor-react";

export const KeyFigureIcons = [
    {
        name: "Prosent",
        icon: <DiscountOutline24Icon />,
    },
    {
        name: "Person",
        icon: <PersonOutline24Icon />,
    },
    {
        name: "Kroner",
        icon: <KronerOutline24Icon />,
    },
];

export const getIconForName = (name: String) =>
    KeyFigureIcons.find((icon) => icon.name === name)?.icon || <DiscountOutline24Icon />;
