import {
    Card,
    VStack,
    Text,
    Box,
    Flex,
    Popover,
    PopoverTrigger,
    Button,
    PopoverArrow,
    PopoverContent,
    InformationOutline24Icon,
    CardProps,
} from "@vygruppen/spor-react";
import { KeyFigureDTO } from "../../api/UseKeyFigures";
import { getIconForName } from "../../common/KeyFigureIcons";
import { PopoverBody } from "@chakra-ui/react";

type KeyFiguresCardProps = {
    keyFigure: KeyFigureDTO;
} & Omit<CardProps, "variant">;

const lastYear = new Date().getFullYear() - 1;

export const KeyFiguresCard = ({ keyFigure, ...cardProps }: KeyFiguresCardProps) => (
    <Card variant="elevated" {...cardProps}>
        <Flex height="100%" flexDirection="column" justifyContent="space-between">
            <VStack height="100%" paddingX={3} paddingTop={3} alignItems="flex-start" justifyContent="space-between">
                <Box>
                    {getIconForName(keyFigure.icon)}
                    <Text marginTop={1} fontSize="16px">
                        {keyFigure.title}
                    </Text>
                    <Text fontSize="30px">{keyFigure.keynumber}</Text>
                </Box>
                <Text fontSize="14px" color="alias.osloGrey">
                    {lastYear}:{" "}
                    <Text as="span" whiteSpace="nowrap">
                        {keyFigure.goal}
                    </Text>
                </Text>
            </VStack>
            <Flex
                width="100%"
                paddingLeft={3}
                paddingY={1}
                marginTop={1}
                backgroundColor="alias.mint"
                justifyContent="space-between"
                alignItems="center"
            >
                <Text fontSize="14px" color="alias.pine">
                    Hittil i år: {keyFigure.currentvalue}
                </Text>
                <KeyFigurePopover text={keyFigure.information} />
            </Flex>
        </Flex>
    </Card>
);

type KeyFigurePopoverProps = {
    text: String;
};

const KeyFigurePopover = ({ text }: KeyFigurePopoverProps) => (
    <Popover>
        <PopoverTrigger>
            <Button variant="ghost" size="xs">
                <InformationOutline24Icon />
            </Button>
        </PopoverTrigger>
        <PopoverContent padding={3}>
            <PopoverArrow />
            <PopoverBody>
                <Text whiteSpace="pre-line">{text}</Text>
            </PopoverBody>
        </PopoverContent>
    </Popover>
);
