import useSWRImmutable from "swr/immutable";
import { getWithAuth, getBackendBaseUrl } from "./apiClientWithAuth";

type UserDTO = {
    username: string;
};

type UseUserdataReturnType = {
    username?: string;
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
};

export const useUserdata = (): UseUserdataReturnType => {
    const { data, error } = useSWRImmutable<UserDTO>(`${getBackendBaseUrl()}/user`, getWithAuth);

    return {
        username: data?.username,
        isSuccess: !error && data != null,
        isLoading: !error && !data,
        isError: error,
    };
};
