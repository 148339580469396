import useSWR, { mutate } from "swr";
import { getWithAuth, getBackendBaseUrl } from "./apiClientWithAuth";

export type KeyFigureDTO = {
    icon: string;
    title: string;
    keynumber: string;
    goal: string;
    currentvalue: string;
    information: string;
};

export type KeyFigureWithIdDTO = KeyFigureDTO & {
    id: number;
};

type UseKeyFiguresReturnType = {
    keyFigures?: KeyFigureWithIdDTO[];
    isLoading: boolean;
    isError: boolean;
};

export const useKeyFigures = (): UseKeyFiguresReturnType => {
    const { data, error } = useSWR<KeyFigureWithIdDTO[]>(`${getBackendBaseUrl()}/keyfigures`, getWithAuth, {
        errorRetryCount: 3,
    });

    return {
        keyFigures: data,
        isLoading: !error && !data,
        isError: error,
    };
};

export const mutateKeyFigures = () => {
    mutate(`${getBackendBaseUrl()}/keyfigures`);
};
