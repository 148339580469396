type Props = {
    symbol: string;
    label?: string;
};

export const Emoji = ({ symbol, label }: Props) => {
    return (
        <span className="emoji" role="img" aria-label={label ? label : ""} aria-hidden={label ? "false" : "true"}>
            {symbol}
        </span>
    );
};
