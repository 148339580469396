import React, { useCallback, useState } from "react";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { Login } from "features/Login/Login";
import useLoginFlow, { useLoginFlowStatus } from "auth/useLoginFlow";
import { useEffectOnce } from "common/hooks/UseEffectOnce";
import { Center, Text } from "@vygruppen/spor-react";
import { FullScreenLoading } from "./components/Loading";

const AuthenticateAndRedirect: React.FC = () => {
    const navigate = useNavigate();
    const { state, verifyPkceCode, fetchToken } = useLoginFlow();
    const [displayFailure, setDisplayFailure] = useState(false);

    useEffectOnce(() => {
        verifyPkceCode();
    });

    const onLoginStatusChange = useCallback(
        (status) => {
            if (status === "Failure") {
                setDisplayFailure(true);
            }

            if (status === "PkceCodeSuccess") {
                fetchToken();
            }

            if (status === "LoggedIn") {
                navigate("/");
            }
        },
        [fetchToken, navigate]
    );

    useLoginFlowStatus(state, onLoginStatusChange);

    if (displayFailure) {
        return (
            <Center minHeight="100vh">
                <Text>Noe gikk galt under innloggingen.</Text>
            </Center>
        );
    }

    return <FullScreenLoading />;
};

export const UnauthenticatedApp: React.FC = () => {
    return (
        <ErrorBoundary>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login-redirect" element={<AuthenticateAndRedirect />} />
                <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
        </ErrorBoundary>
    );
};
