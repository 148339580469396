import { getBackendBaseUrl } from "../../api/apiClient";
import { postWithAuth } from "../../api/apiClientWithAuth";

export type FeedbackDTO = {
    smiley: FeedbackSmiley;
    comment?: string;
};

export enum FeedbackSmiley {
    SAD = "SAD",
    NEUTURAL = "NEUTURAL",
    HAPPY = "HAPPY",
}

export const postFeedback = (feedback: FeedbackDTO) => {
    postWithAuth(`${getBackendBaseUrl()}/feedback`, feedback);
};
