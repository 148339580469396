import React from "react";
import { FullScreenLoading } from "components/Loading";
import { Heading, Text, Center } from "@vygruppen/spor-react";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { Navigate, Route, Routes } from "react-router-dom";
import { Oversiktside } from "./features/Oversiktside/Oversiktside";
import { Domeneside } from "./features/Domeneside/Domeneside";
import { Rapportmodal } from "./features/Domeneside/Rapportmodal";
import { useUserdata } from "./api/UseUserdata";
import { Tabellvisning } from "./features/Tabellvisning/Tabellvisning";
import { Admin } from "features/AdminReports/Admin";
import { AdminKeyfigures } from "features/AdminKeyfigures/AdminKeyfigures";

export const AuthenticatedApp: React.FC = () => {
    const { isSuccess, isLoading } = useUserdata();

    if (isSuccess) {
        return <AuthenticatedRouter />;
    }

    if (isLoading) {
        return <FullScreenLoading />;
    }

    return <UnauthorizedUser />;
};

const AuthenticatedRouter = () => (
    <ErrorBoundary>
        <Routes>
            <Route path="/" element={<Oversiktside />} />
            <Route path="/:domene" element={<Domeneside />}>
                <Route path=":rapportid" element={<Rapportmodal />} />
            </Route>
            <Route path="/admin/nokkeltall" element={<AdminKeyfigures />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/tabell" element={<Tabellvisning />} />
            <Route element={() => <Navigate to="/" />} />
        </Routes>
    </ErrorBoundary>
);

const UnauthorizedUser: React.FC = () => {
    return (
        <Center minHeight="100vh" flexDirection="column">
            <Heading textStyle="md">OBS!</Heading>
            <Text textStyle="sm">Du har ikke tilgang.</Text>
        </Center>
    );
};
