import appConfig from "appConfig.json";

export enum Environment {
    DEV_LOCAL_API = "DEV_LOCAL_API",
    DEVELOPMENT = "DEV",
    TEST = "TEST",
    STAGE = "STAGE",
    PRODUCTION = "PROD",
}

export const getCurrentEnvironment = (): Environment => {
    if (process.env.REACT_APP_LOCAL_API === "true") {
        return Environment.DEV_LOCAL_API;
    }

    if (
        process.env.NODE_ENV === "development" ||
        window.location.origin.includes("localhost") ||
        window.location.origin.includes("127.0.0.1")
    ) {
        return Environment.DEVELOPMENT;
    }

    if (window.location.origin.endsWith(appConfig.environments.TEST)) {
        return Environment.TEST;
    }

    if (window.location.origin.endsWith(appConfig.environments.STAGE)) {
        return Environment.STAGE;
    }

    if (window.location.origin.endsWith(appConfig.environments.PROD)) {
        return Environment.PRODUCTION;
    }

    throw new Error("Fant ikke riktig miljø");
};
