import React, { ReactNode, useMemo } from "react";
import { ReportDTO } from "../api/UseReports";
import { Column, useSortBy, useTable } from "react-table";
import {
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    DropdownDownFill24Icon,
    DropdownUpFill24Icon,
    Flex,
    Heading,
    Text,
} from "@vygruppen/spor-react";
import { categories } from "../common/categories";
import { Center } from "@chakra-ui/react";

type ReportTableProps = {
    reports: ReportDTO[];
    renderCtaCell(report: ReportDTO): ReactNode;
};

export const ReportTable = ({ reports, renderCtaCell }: ReportTableProps) => {
    const columns = useMemo<Column<ReportDTO>[]>(
        () => [
            {
                Header: "Rapport",
                accessor: "name",
                minWidth: 200,
                Cell: ({ value, ...rest }) => (
                    <Flex flexDirection="column">
                        <Heading fontSize="sm">{value}</Heading>
                        <Text fontSize="xs" textOverflow="ellipsis">
                            {rest.row.original.shortDescription}
                        </Text>
                    </Flex>
                ),
            },
            {
                Header: "Kategori",
                minWidth: 175,
                accessor: (report: ReportDTO) => {
                    return categories.find((category) => category.id === report.categoryId)?.name;
                },
            },
            {
                Header: "Ansvarlig",
                accessor: "owner",
                minWidth: 175,
            },
            {
                Header: "Beskrivelse",
                accessor: (report: ReportDTO) => {
                    const maxLength = 100;
                    if (report.longDescription.length > maxLength) {
                        return report.longDescription.slice(0, maxLength - 3) + "...";
                    }
                    return report.longDescription;
                },
            },
            {
                Header: " ",
                accessor: "url",
                disableSortBy: true,
                Cell: ({ row }) => renderCtaCell(row.original),
            },
        ],
        [renderCtaCell]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        { columns, data: reports },
        useSortBy
    );

    if (reports.length === 0) {
        return (
            <Center>
                <Text fontSize="md">Ingen resultater</Text>
            </Center>
        );
    }

    return (
        <Table {...getTableProps()} overflowX="auto">
            <Thead>
                {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <Th {...column.getHeaderProps(column.getSortByToggleProps())} minWidth={column.minWidth}>
                                <Flex>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <DropdownDownFill24Icon aria-label="sorted descending" />
                                            ) : (
                                                <DropdownUpFill24Icon aria-label="sorted ascending" />
                                            )
                                        ) : null}
                                    </span>
                                </Flex>
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <Tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <Td {...cell.getCellProps()} fontSize="xs">
                                    {cell.render("Cell")}
                                </Td>
                            ))}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};
