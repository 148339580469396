import { Link } from "@vygruppen/spor-react";
import { log } from "../../common/log";
import React from "react";

type AddReportLinkProps = {
    onClick?: () => void;
};

export const AddReportLink = ({ onClick }: AddReportLinkProps) => {
    const addReportExternalForm =
        "https://forms.office.com/Pages/ResponsePage.aspx?id=8s9UHilkFEq7GVBLZ5BAWvTM0jEGBFpJtDbbr-DZpHBUMldZTDVZQVBIUUxTR01WNE5YME1XRUlaMy4u";

    return (
        <Link
            rel="noopener noreferrer"
            as={onClick ? undefined : "a"}
            whiteSpace="nowrap"
            variant="primary"
            target="_blank"
            onClick={() => {
                log.clickevent("OPEN_FORM_HEADER");
                if (onClick) {
                    onClick();
                }
            }}
            href={onClick ? undefined : addReportExternalForm}
        >
            Legg til ny rapport
        </Link>
    );
};
