import React from "react";
import { Text, Flex, Stack, Box, Card } from "@vygruppen/spor-react";
import { Link } from "react-router-dom";

type ReportCardProps = {
    name: string;
    text: string;
    to: string;
};

export const ReportCard = ({ name, text, to }: ReportCardProps) => (
    <Card as={Link} to={to} variant="elevated" padding="1rem">
        <Flex direction="column" justifyContent="space-between" height="100%">
            <Stack>
                <Text fontSize="md" fontWeight="bold" noOfLines={1}>
                    {name}
                </Text>
                <Box bg="alias.greenHaze" width="50%" height="6px" borderRadius="5px" />
                <Text fontSize="sm" textOverflow="ellipsis" noOfLines={2}>
                    {text}
                </Text>
            </Stack>
        </Flex>
    </Card>
);
