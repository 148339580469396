import React from "react";
import { Text, Flex, Box, Card } from "@vygruppen/spor-react";
import { IconComponent } from "@vygruppen/spor-icon-react";
import { Link } from "react-router-dom";

type DomeneKortProps = {
    name: string;
    icon: IconComponent;
    to: string;
};

export const DomeneKort = ({ icon: Icon, name, to }: DomeneKortProps) => (
    <Card as={Link} to={to} variant="elevated" padding="0.5rem" height="6rem">
        <Flex direction="column" justifyContent="space-between" height="100%">
            <Box>
                <Icon width="45px" height="45px" bg="alias.mint" borderRadius="50%" padding="6px" />
            </Box>
            <Text paddingLeft="5px" fontSize="xs" fontWeight="bold">
                {name}
            </Text>
        </Flex>
    </Card>
);
