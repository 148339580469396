import React, { ErrorInfo } from "react";
import { Box, Heading, Text } from "@vygruppen/spor-react";
import { log } from "../../common/log";

type ErrorState = {
    errorInfo: ErrorInfo | null;
};

export class ErrorBoundary extends React.Component<any, ErrorState> {
    constructor(props: any) {
        super(props);
        this.state = { errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ errorInfo });
        log.error(error?.message, errorInfo?.componentStack?.toString());
    }

    render() {
        const { props, state } = this;
        if (state.errorInfo) {
            return (
                <Box padding="1rem">
                    <Heading textStyle="md">En feil har oppstått</Heading>
                    <Text textStyle="sm">Feilen er rapportert til utviklerne. Oppdater siden for å gå tilbake</Text>
                    <Text textStyle="sm">
                        Dersom feilen er gjentakende, send gjerne en liten beskrivelse om hvordan du gikk frem da feilen
                        oppstod
                    </Text>
                </Box>
            );
        }
        return props.children;
    }
}
