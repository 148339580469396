import { getBackendBaseUrl } from "../../api/apiClient";
import { postWithAuth, deleteWithAuth, putWithAuth } from "../../api/apiClientWithAuth";
import { KeyFigureDTO } from "../../api/UseKeyFigures";

export const postKeyFigure = (keyFigure: KeyFigureDTO) => {
    return postWithAuth(`${getBackendBaseUrl()}/keyfigures`, keyFigure);
};

export const putKeyFigure = (id: number, keyFigure: KeyFigureDTO) => {
    return putWithAuth(`${getBackendBaseUrl()}/keyfigures/${id}`, keyFigure);
};

export const deleteKeyFigure = (keyFigureId: number) => {
    return deleteWithAuth(`${getBackendBaseUrl()}/keyfigures/${keyFigureId}`);
};
