import { useCallback, useState } from "react";
import { ReportDTO } from "../../api/UseReports";

export const useCategoryFilter = () => {
    const [activeFilters, setActiveFilters] = useState<number[]>([]);

    const setFilterStatus = (categoryId: number, active: boolean) => {
        if (active) {
            setActiveFilters(activeFilters.concat(categoryId));
        } else {
            setActiveFilters(activeFilters.filter((id) => id !== categoryId));
        }
    };

    const getFilteredReports = useCallback(
        (reports?: ReportDTO[]) =>
            reports?.filter((report) => activeFilters.length === 0 || activeFilters.includes(report.categoryId)) || [],
        [activeFilters]
    );

    return {
        getFilteredReports,
        setFilterStatus,
    };
};
