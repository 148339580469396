import React from "react";
import { Button, Text, Box } from "@vygruppen/spor-react";
import {
    SmileFill60Icon,
    SmileOutline60Icon,
    FrownFill60Icon,
    FrownOutline60Icon,
    NeutralFill60Icon,
    NeutralOutline60Icon,
} from "@vygruppen/spor-icon-react";
import { FeedbackSmiley } from "./FeedbackApi";

type FeedbackEmojiButtonProps = {
    value: FeedbackSmiley;
    selectedValue: FeedbackSmiley | null;
    onClick: (value: FeedbackSmiley) => void;
};

const smileyMap = {
    [FeedbackSmiley.SAD]: {
        name: "Dårlig",
        icon: <FrownOutline60Icon />,
        iconSelected: <FrownFill60Icon />,
    },
    [FeedbackSmiley.NEUTURAL]: {
        name: "Nøytral",
        icon: <NeutralOutline60Icon />,
        iconSelected: <NeutralFill60Icon />,
    },
    [FeedbackSmiley.HAPPY]: {
        name: "Bra",
        icon: <SmileOutline60Icon />,
        iconSelected: <SmileFill60Icon />,
    },
};

export const FeedbackEmojiButton = ({ value, selectedValue, onClick }: FeedbackEmojiButtonProps) => (
    <Button variant="ghost" height="100px" onClick={() => onClick(value)}>
        <Box>
            <Box height="65px">{selectedValue === value ? smileyMap[value].iconSelected : smileyMap[value].icon}</Box>
            <Text>{smileyMap[value].name}</Text>
        </Box>
    </Button>
);
