import { ReportDTO } from "api/UseReports";
import { getBackendBaseUrl } from "../../api/apiClient";
import { postWithAuth, deleteWithAuth, putWithAuth, postFormDataWithAuth } from "../../api/apiClientWithAuth";

export const postReport = (report: ReportDTO) => {
    return postWithAuth(`${getBackendBaseUrl()}/reports`, report);
};

export const putReport = (report: ReportDTO) => {
    return putWithAuth(`${getBackendBaseUrl()}/reports`, report);
};

export const deleteReportById = (reportId: number) => {
    return deleteWithAuth(`${getBackendBaseUrl()}/reports/${reportId}`);
};

export const postFile = (file: File): Promise<{ value: string }> => {
    const formData = new FormData();
    formData.append("file", file);

    return postFormDataWithAuth(`${getBackendBaseUrl()}/file`, formData);
};
