import { getState } from "../auth/storage";
import { hasAccessToken } from "../auth/loginFlowTypes";
import * as loginFlow from "../auth/loginFlow";

export { getBackendBaseUrl } from "./apiClient";

const getAccessToken = () => {
    const state = getState();

    if (hasAccessToken(state)) {
        return state.accessToken;
    }

    throw new Error("Api attempting use accesstoken when it is not available");
};

const fetchWithAuth = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const headers = {
        ...init?.headers,
        Authorization: buildAuthHeader(),
    };

    return fetch(input, { ...init, headers }).then((response) => {
        if (response.status === 401) {
            loginFlow.setExpiredAccessToken();

            return loginFlow.refreshToken().then((result) => {
                if (result.status === "LoggedIn") {
                    const newHeaders = { ...headers, Authorization: `Bearer ${result.accessToken}` };
                    return fetch(response.url, { ...init, headers: newHeaders });
                }

                throw new Error(`Feil status etter refresh av token: ${result.status}`);
            });
        }
        return response;
    });
};

export const buildAuthHeader = (): string => `Bearer ${getAccessToken()}`;

export const getWithAuth = <TYPE>(input: RequestInfo, init?: RequestInit): Promise<TYPE> =>
    fetchWithAuth(input, init).then((res) => res.json());

export const postWithAuth = <TYPE>(input: RequestInfo, body: any, init?: RequestInit): Promise<TYPE> =>
    fetchWithAuth(input, {
        ...init,
        method: "POST",
        headers: { "Content-Type": "application/json", ...init?.headers },
        body: JSON.stringify(body),
    }).then((res) => res.json());

export const postFormDataWithAuth = <TYPE>(input: RequestInfo, body: any, init?: RequestInit): Promise<TYPE> =>
    fetchWithAuth(input, {
        ...init,
        method: "POST",
        headers: init?.headers,
        body: body,
    }).then((res) => res.json());

export const putWithAuth = <TYPE>(input: RequestInfo, body: any, init?: RequestInit): Promise<TYPE> =>
    fetchWithAuth(input, {
        ...init,
        method: "PUT",
        headers: { "Content-Type": "application/json", ...init?.headers },
        body: JSON.stringify(body),
    }).then((res) => res.json());

export const deleteWithAuth = <TYPE>(input: RequestInfo, init?: RequestInit): Promise<TYPE> =>
    fetchWithAuth(input, {
        ...init,
        method: "DELETE",
    }).then((res) => res.json());
