import { Spinner, SpinnerProps } from "@chakra-ui/react";
import { Center } from "@vygruppen/spor-react";

export const Loading = ({ size = "xl", ...props }: SpinnerProps) => <Spinner size={size} {...props} />;

export const FullScreenLoading = ({ size = "xl", ...props }: SpinnerProps) => (
    <Center minHeight="100vh">
        <Spinner size={size} {...props} />
    </Center>
);
