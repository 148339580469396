import React from "react";
import { ArrowLeftOutline24Icon, Heading, HStack, TextLink, Flex, Box } from "@vygruppen/spor-react";
import { Link as ReactRouterLink, Link as RouterLink } from "react-router-dom";

type AdminKeyfiguresHeaderProps = {
    buttonDisabled: boolean;
    onClick: () => void;
};

export const AdminKeyfiguresHeader = ({ buttonDisabled, onClick }: AdminKeyfiguresHeaderProps) => (
    <Flex width="100%" flexDirection="row" justifyContent="space-between" alignItems="center">
        <HStack>
            <RouterLink to={"/"}>
                <ArrowLeftOutline24Icon
                    height="30px"
                    width="30px"
                    color="alias.darkTeal"
                    title={"Tilbake til forrige side"}
                />
            </RouterLink>
            <Box textAlign={["center", "left"]} paddingLeft={[0, 2]} width="100%">
                <Heading fontSize="lg" fontWeight="600">
                    Admin: nøkkeltall
                </Heading>
                <TextLink whiteSpace="nowrap" as={ReactRouterLink} to="/admin">
                    Administrer rapporter
                </TextLink>
            </Box>
        </HStack>
        <Box>
            <TextLink
                textColor={buttonDisabled ? "grey" : undefined}
                as="button"
                disabled={buttonDisabled}
                onClick={onClick}
            >
                Legg til nytt nøkkeltall
            </TextLink>
        </Box>
    </Flex>
);
