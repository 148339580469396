import { Box, Text, Flex } from "@vygruppen/spor-react";
import { useKeyFigures } from "../../api/UseKeyFigures";
import { KeyFiguresCard } from "./KeyFiguresCard";

export const KeyFigures = () => {
    const { keyFigures, isLoading } = useKeyFigures();

    if (!keyFigures || isLoading || keyFigures.length === 0) {
        return null;
    }

    // We only want to show the 5 first even if there for some reason is more in the database
    const MAX_NUM_KEY_FIGURES = 5;
    const keyFiguresToShow = keyFigures.slice(0, MAX_NUM_KEY_FIGURES).sort((a, b) => a.id - b.id);
    const numberOfKeyFiguresToShow = keyFigures.length;

    return (
        <Box marginTop={3}>
            <Text>
                Nøkkeltall for{" "}
                <Text as="span" fontWeight={600}>
                    tog
                </Text>
            </Text>
            <Flex
                justifyContent={
                    numberOfKeyFiguresToShow === MAX_NUM_KEY_FIGURES
                        ? ["flex-start", "flex-start", "space-between", "space-between"]
                        : "flex-start"
                }
                gap={4}
                direction="row"
                wrap={["wrap", "wrap", "nowrap"]}
            >
                {keyFiguresToShow.map((keyFigure) => (
                    <KeyFiguresCard
                        key={keyFigure.id}
                        width={["100%", "230px"]}
                        keyFigure={keyFigure}
                        marginTop={4}
                    />
                ))}
            </Flex>
        </Box>
    );
};
