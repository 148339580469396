import React, { useState } from "react";
import { AppContainer } from "components/AppContainer";
import { Outlet } from "react-router-dom";
import { FullScreenLoading } from "components/Loading";
import { Box, Text, Center, Flex, Button, EditOutline24Icon, DeleteOutline24Icon } from "@vygruppen/spor-react";
import { AdminKeyfiguresHeader } from "./AdminKeyfiguresHeader";
import { KeyFigureWithIdDTO, useKeyFigures } from "../../api/UseKeyFigures";
import { DeleteKeyfigureDialog } from "./DeleteKeyfigureDialog";
import { AddUpdateKeyfiguresModal } from "./AddUpdateKeyfiguresModal";
import { List, ListItem } from "@chakra-ui/react";

export const AdminKeyfigures = () => {
    const { keyFigures, isLoading } = useKeyFigures();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isAddKeyFigureOpen, setIsAddKeyFigureOpen] = useState(false);
    const [editKeyFigureId, setEditKeyFigureId] = useState<number | null>(null);
    const [keyFigureToDelete, setKeyFigureToDelete] = useState<KeyFigureWithIdDTO | null>(null);

    const showDeleteDialog = (keyfigure: KeyFigureWithIdDTO) => {
        setKeyFigureToDelete(keyfigure);
        setIsDeleteOpen(true);
    };

    const closeAddReportDialog = () => {
        setEditKeyFigureId(null);
        setIsAddKeyFigureOpen(false);
    };

    const handleAddNewKeyFigure = () => {
        if (keyFigures && keyFigures.length < 5) {
            setIsAddKeyFigureOpen(true);
        }
    };

    const compareById = (a: KeyFigureWithIdDTO, b: KeyFigureWithIdDTO) => a.id - b.id;

    const keyFigureToEdit = keyFigures?.find((keyfigure) => keyfigure.id === editKeyFigureId);
    const addButtonDisabled = keyFigures == null || keyFigures.length >= 5;

    return (
        <AppContainer
            showTableViewButton={false}
            header={<AdminKeyfiguresHeader buttonDisabled={addButtonDisabled} onClick={handleAddNewKeyFigure} />}
        >
            {isLoading && <FullScreenLoading />}
            {keyFigures && (
                <List marginTop={6}>
                    {keyFigures.sort(compareById).length > 0 ? (
                        keyFigures.map((keyfigure, index) => (
                            <ListItem
                                paddingX={4}
                                paddingY={2}
                                borderTop={index === 0 ? "1px solid" : undefined}
                                borderBottom="1px solid"
                            >
                                <Flex justifyContent="space-between">
                                    <Text fontSize="md" fontWeight={900}>
                                        {keyfigure.title}
                                    </Text>
                                    <Flex direction="row">
                                        <Button onClick={() => setEditKeyFigureId(keyfigure.id)}>
                                            <EditOutline24Icon />
                                        </Button>
                                        <Button marginLeft={1} onClick={() => showDeleteDialog(keyfigure)}>
                                            <DeleteOutline24Icon />
                                        </Button>
                                    </Flex>
                                </Flex>
                            </ListItem>
                        ))
                    ) : (
                        <Box mt={8}>
                            <Center>
                                <Text fontSize="md">Ingen nøkkeltall lagt til enda</Text>
                            </Center>
                        </Box>
                    )}
                </List>
            )}
            <DeleteKeyfigureDialog
                onClose={() => setIsDeleteOpen(false)}
                isOpen={isDeleteOpen}
                keyFigureToDelete={keyFigureToDelete}
            />
            <AddUpdateKeyfiguresModal
                onClose={closeAddReportDialog}
                isOpen={isAddKeyFigureOpen || editKeyFigureId != null}
                keyFigureToEdit={keyFigureToEdit}
            />
            <Outlet />
        </AppContainer>
    );
};
