import { AppContainer } from "../../components/AppContainer";
import { useParams, Outlet } from "react-router-dom";
import { useReports } from "../../api/UseReports";
import { FullScreenLoading } from "../../components/Loading";
import { EmptyPage } from "./EmptyPage";
import { DomenesideBody } from "./DomenesideBody";
import { StandardHeader } from "../../components/Header/StandardHeader";

export const Domeneside = () => {
    const { domene } = useParams();
    const { reports, categoryName, isLoading } = useReports(domene!);

    return (
        <AppContainer showTableViewButton={false} header={<StandardHeader backLinkTo="/" name={categoryName} />}>
            {isLoading && <FullScreenLoading />}
            {reports && <>{reports.length > 0 ? <DomenesideBody reports={reports} /> : <EmptyPage />}</>}
            <Outlet />
        </AppContainer>
    );
};
