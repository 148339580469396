import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Image,
    Center,
} from "@vygruppen/spor-react";

type Props = {
    reportName: string;
    onClose: () => void;
    isOpen: boolean;
    imageUrl: string;
};

export const ReportImagePreviewModal = ({ reportName, onClose, isOpen, imageUrl }: Props) => {
    return (
        <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{reportName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center width="100%">
                        <Image height="85vh" objectFit="contain" src={imageUrl} />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
