import { Button, Center, Container, Heading, ProfileOutline24Icon, Text } from "@vygruppen/spor-react";
import useLoginFlow from "auth/useLoginFlow";
import { useState } from "react";

export const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { fetchSettings, login } = useLoginFlow();

    const onClickLogin = () => {
        setIsLoading(true);
        fetchSettings().then(login);
    };

    return (
        <Center minHeight="100vh" color="alias.white" bg="alias.darkTeal">
            <Container flexDirection="column" textAlign="center" alignItems="center">
                <Heading fontSize="xl" marginBottom="4">
                    Velkommen til Vy Tall!
                </Heading>
                <Text marginBottom="8">
                    Her kan du finne tall og analyser for det som foregår i Vy tog. Alt samlet på et sted, enkelt å
                    finne, enkelt å bruke.
                </Text>
                <Button variant="secondary" marginBottom="2rem" isLoading={isLoading} onClick={onClickLogin}>
                    <ProfileOutline24Icon marginRight="4px" />
                    Logg inn
                </Button>
            </Container>
        </Center>
    );
};
