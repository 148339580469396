import { ChangeEvent } from "react";
import { Box, Flex, ChoiceChip, Card, Button } from "@vygruppen/spor-react";
import { AppContainer } from "../../components/AppContainer";
import { useReports } from "../../api/UseReports";
import { FullScreenLoading } from "../../components/Loading";
import { ReportTable } from "../../components/ReportTable";
import { Feedback } from "../../components/Feedback/Feedback";
import { categories } from "../../common/categories";
import { useCategoryFilter } from "./TabellvisningHooks";
import { log } from "../../common/log";
import { useSearch, useSearchUri } from "../../components/Search/SearchHook";
import { StandardHeader } from "../../components/Header/StandardHeader";

export const Tabellvisning = () => {
    const { isLoading, reports } = useReports();
    const { getFilteredReports, setFilterStatus } = useCategoryFilter();
    const { searchStringFromUri } = useSearchUri();
    const searchedReports = useSearch(searchStringFromUri, reports || []);

    const onChipChange = (categoryId: number, event: ChangeEvent<HTMLInputElement>) => {
        setFilterStatus(categoryId, event.currentTarget.checked);
    };

    const filteredReports = getFilteredReports(searchedReports);

    return (
        <AppContainer header={<StandardHeader name="Vy tall" showAddReport={false} />}>
            <Box>
                {isLoading && <FullScreenLoading />}
                {!isLoading && reports && (
                    <Card variant="elevated" padding={[2, 4]}>
                        <Flex
                            wrap="wrap"
                            direction="row"
                            justifyContent={["flex-start", "flex-start", "space-between"]}
                            marginBottom={6}
                        >
                            {categories.map((category) => (
                                <ChoiceChip
                                    size="sm"
                                    key={category.id}
                                    marginRight={1}
                                    onChange={(e) => onChipChange(category.id, e)}
                                    marginTop={4}
                                    marginX={1}
                                >
                                    {category.name}
                                </ChoiceChip>
                            ))}
                        </Flex>
                        <ReportTable
                            reports={filteredReports}
                            renderCtaCell={(report) => (
                                <Button
                                    size="xs"
                                    as="a"
                                    href={report.url}
                                    target="_blank"
                                    onClick={() => log.clickevent(`OPEN_REPORT_TABLE_VIEW: ${report.name}`)}
                                >
                                    Gå til rapport
                                </Button>
                            )}
                        />
                    </Card>
                )}
            </Box>
            <Feedback marginBottom={6} maxWidth="450px" marginX="auto" />
        </AppContainer>
    );
};
