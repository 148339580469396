import React from "react";
import { Flex, Box, VStack, Container, Button, GridOutline24Icon } from "@vygruppen/spor-react";
import { Token } from "@chakra-ui/styled-system/dist/declarations/src/utils";
import * as CSS from "csstype";
import { useMatch, Link as RouterLink } from "react-router-dom";

type AppContainerProps = {
    header: React.ReactNode;
    children: React.ReactNode;
    paddingHeader?: Token<CSS.Property.Padding | number, "space">;
    paddingContent?: Token<CSS.Property.Padding | number, "space">;
    maxWHeader?: string;
    maxWContent?: string;
    showTableViewButton?: boolean;
};

export const AppContainer = ({
    header,
    children,
    paddingHeader = "3",
    paddingContent = "3",
    maxWHeader = "container.xl",
    maxWContent = "container.xl",
    showTableViewButton = true,
}: AppContainerProps) => {
    return (
        <VStack minHeight="100vh" minWidth="100%" bg="alias.white">
            <Box width="100%" marginTop="0.5rem">
                <Container padding={paddingHeader} maxW={maxWHeader}>
                    {header}
                </Container>
            </Box>

            <Flex marginTop={0} width="100%" bg="alias.lightGrey" flex="1">
                <Container padding={paddingContent} maxW={maxWContent} flex="1">
                    {showTableViewButton && (
                        <Box marginBottom={3} textAlign="right" width="100%">
                            <TabellvisningKnapp />
                        </Box>
                    )}
                    {children}
                </Container>
            </Flex>
        </VStack>
    );
};

const TabellvisningKnapp = () => {
    const match = useMatch("tabell");
    const linkUrl = match == null ? "/tabell" : "/";
    const linkText = match == null ? "Se tabellvisning" : "Se oversikt";

    return (
        <Button as={RouterLink} size="xs" to={linkUrl} variant="additional" leftIcon={<GridOutline24Icon />}>
            {linkText}
        </Button>
    );
};
