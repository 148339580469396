import { Box } from "@vygruppen/spor-react";
import { Search } from "../Search/Search";
import React from "react";
import { useSearchUri } from "../Search/SearchHook";

type SearchInputProps = {
    onChange?: (value: string) => void;
};

export const SearchInput = ({ onChange }: SearchInputProps) => {
    const { updateSearchUri, searchStringFromUri } = useSearchUri();
    return (
        <Box display={["none", "none", "initial"]} marginLeft={4}>
            <Search
                label="Søk etter rapport"
                autoFocus={searchStringFromUri !== ""}
                setSearchString={(value) => {
                    onChange ? onChange(value) : updateSearchUri(value);
                }}
            />
        </Box>
    );
};
