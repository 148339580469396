import { SearchInput } from "@vygruppen/spor-input-react";
import { useSearchUri } from "./SearchHook";

type Props = {
    label: string;
    autoFocus?: boolean;
    setSearchString: (value: string) => void;
};

export const Search = ({ label, setSearchString, autoFocus }: Props) => {
    const { searchStringFromUri } = useSearchUri();
    return (
        <SearchInput
            onChange={(e) => setSearchString(e.target.value)}
            autoFocus={autoFocus}
            defaultValue={searchStringFromUri || ""}
            onReset={() => setSearchString("")}
            label={label}
        />
    );
};
