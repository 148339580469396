import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    Center,
    ModalBody,
    Heading,
    Box,
    Text,
    Button,
    LinkOutOutline24Icon,
    BoxProps,
} from "@vygruppen/spor-react";
import { useReports } from "../../api/UseReports";
import { Loading } from "../../components/Loading";
import { log } from "../../common/log";
import { isNotEmpty } from "../../common/utils";
import { ReportImagePreview } from "./ReportImagePreview";

export const Rapportmodal = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { domene } = useParams();

    return (
        <Modal
            size="lg"
            isOpen={true}
            onClose={() => {
                if (location.key === "default") {
                    // if this is the initial site the key is "default", meaning we opened the site through a deeplink/boomark
                    // using "go back" does not make sense in this context. So we need to navigate to the main site normally
                    navigate(`/${domene}`, { replace: true });
                } else {
                    // We close the modal by going to the previous site. This makes sure the back-button will
                    // not reopen the modal. But clicking the back-button with modal open will close it.
                    navigate(-1);
                }
            }}
        >
            <ModalOverlay />
            <ModalContent>
                <RapportModalContent />
            </ModalContent>
        </Modal>
    );
};

const RapportModalContent = () => {
    const { domene, rapportid } = useParams();
    const { reports, isLoading } = useReports(domene!);
    const report = reports?.find((report) => report.id === parseInt(rapportid || "0", 10));

    if (!rapportid || !report || isLoading) {
        return (
            <Center>
                <Loading />
            </Center>
        );
    }

    return (
        <ModalBody>
            <Heading fontSize="md" marginTop={2} marginBottom={1}>
                {report.name}
            </Heading>
            <Box marginBottom={1} bg="alias.greenHaze" width="50%" height="6px" borderRadius="5px" />
            <Text fontSize="sm">{report.shortDescription}</Text>
            <ReportImagePreview previewImages={report.previewImages} reportName={report.name} />
            <Button
                marginTop={4}
                marginBottom={4}
                as="a"
                href={report.url}
                onClick={() => {
                    log.clickevent(`OPEN_REPORT: ${report.name}`);
                }}
                isFullWidth={true}
                target="_blank"
                rel="noreferrer noopener"
            >
                Gå til rapport
                <LinkOutOutline24Icon marginLeft={1} />
            </Button>

            <TextBlock heading="Ansvarlig" content={report.owner} />
            <TextBlock heading="Beskrivelse" content={report.longDescription} />

            {isNotEmpty(report.lastUpdated) && <TextBlock heading="Sist oppdatert" content={report.lastUpdated} />}
            {isNotEmpty(report.sources) && <TextBlock heading="Kilde" content={report.sources} />}

            <TextBlock
                heading="Denne rapporten er tilgangsstyrt 🔒"
                content="Ta kontakt med ansvarlig hvis du ønsker tilgang."
                mb="0"
            />
        </ModalBody>
    );
};

type TextBlockProps = {
    heading: React.ReactNode;
    content: React.ReactNode;
} & BoxProps;

const TextBlock = ({ heading, content, ...props }: TextBlockProps) => (
    <Box mb="4" {...props}>
        <Heading as="h3" textStyle="xs" fontSize="14px">
            {heading}
        </Heading>
        <Text fontSize="sm" whiteSpace="pre-line">
            {content}
        </Text>
    </Box>
);
