import { Center, Text, Button } from "@vygruppen/spor-react";
import { Emoji } from "components/Emoji";
import { log } from "../../common/log";

export const EmptyPage = () => {
    return (
        <Center height="xs" flexDirection="column" justifyContent="space-evenly">
            <Text fontSize="md" color="alias.pine">
                Oi! Her var det tomt <Emoji symbol="&#128584;" />
            </Text>
            <Button
                variant="additional"
                size="xs"
                as="a"
                onClick={() => {
                    log.clickevent("OPEN_FORM_EMPTY_PAGE");
                }}
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=8s9UHilkFEq7GVBLZ5BAWvTM0jEGBFpJtDbbr-DZpHBUMldZTDVZQVBIUUxTR01WNE5YME1XRUlaMy4u"
                target="_blank"
                rel="noreferrer noopener"
            >
                Legg til ny rapport
            </Button>
        </Center>
    );
};
