import { useRef } from "react";
import {
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
} from "@chakra-ui/react";
import { KeyFigureWithIdDTO, mutateKeyFigures } from "../../api/UseKeyFigures";
import { deleteKeyFigure } from "./AdminKeyfiguresApi";

type Props = {
    onClose: () => void;
    isOpen: boolean;
    keyFigureToDelete: KeyFigureWithIdDTO | null;
};

export const DeleteKeyfigureDialog = ({ onClose, isOpen, keyFigureToDelete }: Props) => {
    const cancelRef = useRef(null);

    const handleDelete = async () => {
        if (keyFigureToDelete) {
            await deleteKeyFigure(keyFigureToDelete.id);
            mutateKeyFigures();
            onClose();
        }
    };

    return (
        <>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Slett nøkkeltall
                        </AlertDialogHeader>

                        <AlertDialogBody>Er du sikker? Du kan ikke angre.</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Avbryt
                            </Button>
                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                Slett
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
