import { useMemo, useCallback } from "react";
import { useThrottleCallback } from "@react-hook/throttle";
import { ReportDTO } from "../../api/UseReports";
import { categories } from "../../common/categories";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

const searchStringInReport = (report: ReportDTO, searchString: string) => {
    const fieldsToSearch = [
        report.name,
        report.shortDescription,
        report.longDescription,
        report.owner,
        categories.find((category) => category.id === report.categoryId)?.name || "",
    ];

    return fieldsToSearch.some((field) => field.toLowerCase().includes(searchString.toLowerCase()));
};

export const useSearch = (searchString: string, reports: ReportDTO[]) =>
    useMemo(() => {
        if (searchString === "") {
            return reports;
        }
        return reports.filter((report) => searchStringInReport(report, searchString));
    }, [searchString, reports]);

export const useSearchUri = () => {
    const navigate = useNavigate();
    const match = useMatch("tabell");
    const [searchParams, setSearchParams] = useSearchParams();

    const updateSearchUri = useCallback(
        (searchString: string) => {
            const isNotOnTableViewPage = match == null;
            if (isNotOnTableViewPage && searchString.trim() !== "") {
                navigate(`/tabell?q=${searchString}`);
            } else {
                setSearchParams(searchString && { q: searchString }, { replace: true });
            }
        },
        [match, navigate, setSearchParams]
    );

    const throttledUpdateSearchUri = useThrottleCallback(updateSearchUri, 6, true);
    const searchStringFromUri = searchParams.get("q")?.trim() || "";

    return { updateSearchUri: throttledUpdateSearchUri, searchStringFromUri };
};
