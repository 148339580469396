import appConfig from "appConfig.json";
import { getBackendBaseUrl, post } from "../api/apiClient";

const baseUrl = getBackendBaseUrl();

export enum LogLevel {
    ERROR = "error",
    WARNING = "warn",
    INFO = "info",
}

type LogPayload = {
    logLevel: LogLevel;
    source: string;
    data: {
        username: string | null;
        componentStack: string;
        type: string;
    };
    message?: string;
};

const logPayload = (
    logLevel: LogLevel,
    message: string,
    componentStack?: string,
    type: string = "DEFAULT"
): LogPayload => ({
    logLevel,
    source: appConfig.name,
    data: {
        username: "INSERT_USERNAME_HERE",
        componentStack: componentStack || "Not Available",
        type,
    },
    message,
});

export const sendLogToCloudWatch = (payload: LogPayload) =>
    post(`${baseUrl}/frontend-logger/log`, payload, {
        method: "POST",
    });

export const log = {
    info: (message: string, componentStack?: string) =>
        sendLogToCloudWatch(logPayload(LogLevel.INFO, message, componentStack)),
    warning: (message: string, componentStack?: string) =>
        sendLogToCloudWatch(logPayload(LogLevel.WARNING, message, componentStack)),
    error: (message: string, componentStack?: string) =>
        sendLogToCloudWatch(logPayload(LogLevel.ERROR, message, componentStack)),
    pageview: (page: string) => sendLogToCloudWatch(logPayload(LogLevel.INFO, page, undefined, "PAGEVIEW")),
    clickevent: (event: string) => sendLogToCloudWatch(logPayload(LogLevel.INFO, event, undefined, "CLICKEVENT")),
};
